<template>
    <CModal
        title="事業所削除"
        color="dark"
        size="sm"
        :show.sync="isOpened">
        <div class="py-1">事業所名：{{ name }}</div>
        <div class="py-1">管理者名：{{ managerName }}</div>
        <template #footer>
            <button type="button" class="btn btn-danger" @click="deleteConfirm" :disabled="sending">削除</button>
        </template>
    </CModal>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
export default {
    name: "DeleteOfficeModal",
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            name: '',
            managerName: ''
        }
    },
    methods: {
        openModal: function (office) {
            this.isOpened = true;
            this.id = office.id;
            this.name = office.attributes.name;
            this.managerName = office.attributes.manager_name;

        },
        deleteOffice: function () {
            this.showIndicator('削除中');
            this.sending = true;

            axios.delete(this.routes.office(this.id))
                .then(response => {
                    this.$emit('office-has-deleted', response.data.data);
                    this.showSuccessPopup('削除しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        deleteConfirm: function () {
            this.showDeleteConfirmPopup(() => {
                this.deleteOffice();
            })
        }
    }
}
</script>

<style scoped>

</style>
