<template>
    <div>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 10%">事業所名</th>
                <th style="width: 10%">管理者名</th>
                <th style="width: 40%">住所</th>
                <th style="width: 10%">法人名</th>
                <th style="width: 10%">電話番号</th>
                <th style="width: 10%">編集</th>
                <th style="width: 10%">削除</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="office in offices">
                <tr :class="{'is-deleted': (office.attributes.deleted_at !== null)}">
                    <td>{{ office.attributes.name }}</td>
                    <td>{{ office.attributes.manager_name }}</td>
                    <td>{{ office.attributes.address }}</td>
                    <td>{{ office.attributes.company_name }}</td>
                    <td>{{ office.attributes.tel }}</td>
                    <template v-if="office.attributes.deleted_at === null">
                        <td><a href="javascript:void(0)" @click="openEditModal(office)">編集</a></td>
                        <td><a href="javascript:void(0)" @click="openDeleteModal(office)">削除</a></td>
                    </template>
                    <template v-else>
                        <td colspan="2" class="text-center"><a href="javascript:void(0)" @click="restoreConfirm(office)">復元</a></td>
                    </template>
                </tr>
            </template>
            </tbody>
        </table>
        <pagination :meta="meta" @paginate="$emit('paginate', $event)"></pagination>
        <edit-office-modal ref="editModal"
                           @office-has-updated="$emit('office-has-updated', $event)">
        </edit-office-modal>
        <delete-office-modal ref="deleteModal"
                             @office-has-deleted="$emit('office-has-deleted', $event)">
        </delete-office-modal>
    </div>
</template>

<script>
import EditOfficeModal from "@/components/Offices/EditOfficeModal/EditOfficeModal";
import DeleteOfficeModal from "@/components/Offices/DeleteOfficeModal/DeleteOfficeModal";
import Pagination from "@/components/Pagination/Pagination";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "OfficeList",
    components: {Pagination, DeleteOfficeModal, EditOfficeModal},
    mixins: [ApiRouter, ErrorHandler],
    props: ['offices', 'meta'],
    data: function () {
        return {}
    },
    methods: {
        openEditModal: function (office) {
            this.$refs['editModal'].openModal(office);
        },
        openDeleteModal: function (office) {
            this.$refs['deleteModal'].openModal(office);
        },
        restoreOffice: function (office) {
            this.showIndicator('復元中');

            axios.patch(this.routes.officeRestore(office.id))
                .then(response => {
                    this.showSuccessPopup('復元しました');
                    this.$emit('office-has-restored', response.data.data);
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        restoreConfirm: function (office) {
            this.showRestoreConfirmPopup(() => {
                this.restoreOffice(office);
            })
        }
    }
}
</script>

<style src="./OfficeList.scss" lang="scss" scoped/>
