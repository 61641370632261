<template>
    <CModal
        title="事業所登録"
        color="dark"
        size="lg"
        :show.sync="isOpened">
        <form>
            <div class="form">
                <v-input label="事業所名"
                         id="create-name"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('name')"
                         v-model="form.name">
                </v-input>
                <v-input label="管理者名"
                         id="create-manager-name"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('manager_name')"
                         v-model="form.manager_name">
                </v-input>
                <v-input label="住所"
                         id="create-address"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('address')"
                         v-model="form.address">
                </v-input>
                <v-input label="法人名"
                         id="create-company-name"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('company_name')"
                         v-model="form.company_name">
                </v-input>
                <v-input label="電話番号"
                         id="create-tel"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('tel')"
                         v-model="form.tel">
                </v-input>
            </div>
        </form>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="createOffice" :disabled="sending">登録</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
export default {
    name: "CreateOfficeModal",
    components: {VInput},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            form: {
                name: '',
                manager_name: '',
                address: '',
                company_name: '',
                tel: ''
            }
        }
    },
    methods: {
        openModal: function () {
            this.isOpened = true;
            this.initForm();
            this.clearErrors();
        },
        initForm: function () {
            this.form.name = '';
            this.form.manager_name = '';
            this.form.address = '';
            this.form.company_name = '';
            this.form.tel = '';
        },
        createOffice: function () {
            this.showIndicator('登録中');
            this.sending = true;

            axios.post(this.routes.offices, this.form)
                .then(response => {
                    this.$emit('office-has-created', response.data.data);
                    this.showSuccessPopup('登録しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>

</style>
