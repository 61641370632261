<template>
    <div class="container-fluid">
        <div class="row pb-2">
            <div class="col-6">
                <h3>#事業所一覧</h3>
            </div>
            <div class="col-6">
                <button type="button"
                        class="btn btn-outline-dark float-right mb-2"
                        @click="openCreateModal">＋
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <office-list :offices="offices"
                             :meta="meta"
                             @paginate="paginate"
                             @office-has-updated="updateOffice"
                             @office-has-deleted="deleteOffice"
                             @office-has-restored="restoreOffice">
                </office-list>
            </div>
        </div>
        <create-office-modal ref="createModal" @office-has-created="createdOffice"></create-office-modal>
    </div>
</template>

<script>
import CreateOfficeModal from "@/components/Offices/CreateOfficeModal/CreateOfficeModal";
import OfficeList from "@/components/Offices/OfficeList/OfficeList";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "Offices",
    components: {OfficeList, CreateOfficeModal},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            offices: {},
            meta: {}
        }
    },
    created: function () {
        this.loadOffices();
    },
    methods: {
        openCreateModal: function () {
            this.$refs['createModal'].openModal();
        },
        loadOffices: function () {
            axios.get(this.routes.offices)
                .then(response => {
                    this.offices = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        paginate: function (page) {
            axios.get(this.routes.offices, {
                params: {
                    page: page
                }
            })
                .then(response => {
                    this.offices = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        createdOffice: function (office) {
            this.offices.unshift(office);
        },
        updateOffice: function ($event) {
            const index = this.offices.findIndex(function (office) {
                return office.id === $event.id;
            });
            if (index !== -1) {
                this.offices.splice(index, 1, $event);
            }
        },
        deleteOffice: function ($event) {
            const index = this.offices.findIndex(function (office) {
                return office.id === $event.id;
            });
            this.offices.splice(index, 1);

            this.offices.push($event);
        },
        restoreOffice: function ($event) {
            const index = this.offices.findIndex(function (office) {
                return office.id === $event.id;
            });
            if (index !== -1) {
                this.offices.splice(index, 1, $event);
            }
        },
    }
}
</script>

<style scoped>

</style>
