<template>
    <nav v-if="meta.total > 0">
        <ul class="pagination">
            <li class="page-item" v-if="meta.current_page !== 1">
                <a class="page-link text-dark" href="javascript:void(0)" aria-label="Previous" @click="paginate(meta.current_page - 1)">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(1)}"
                v-if="meta.last_page !== 1">
                <a class="page-link" href="javascript:void(0)" @click="paginate(1)">1</a>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(2)}"
                v-if="meta.current_page < 4 && meta.last_page > 2">
                <a class="page-link" href="javascript:void(0)" @click="paginate(2)">2</a>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(3)}"
                v-if="meta.current_page < 4 && meta.last_page > 3">
                <a class="page-link" href="javascript:void(0)" @click="paginate(3)">3</a>
            </li>
            <li class="page-item" v-if="meta.current_page >= 4">
                <span class="page-link">...</span>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(meta.current_page - 1)}"
                v-if="meta.current_page >= 4">
                <a class="page-link" href="javascript:void(0)" @click="paginate(meta.current_page - 1)">
                    {{ meta.current_page - 1 }}
                </a>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(meta.current_page)}"
                v-if="meta.current_page >= 4 && meta.last_page !== meta.current_page">
                <a class="page-link" href="javascript:void(0)" @click="paginate(meta.current_page)">
                    {{ meta.current_page }}
                </a>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(meta.current_page + 1)}"
                v-if="meta.current_page >= 3 && (meta.current_page + 1) <= meta.last_page &&
               (meta.current_page + 1) !== meta.last_page">
                <a class="page-link" href="javascript:void(0)" @click="paginate(meta.current_page + 1)">
                    {{ meta.current_page + 1 }}
                </a>
            </li>
            <li class="page-item" v-if="((meta.last_page - (meta.current_page + 1)) > 1) && (meta.last_page > 4)">
                <span class="page-link">...</span>
            </li>
            <li class="page-item"
                :class="{'active': isCurrent(meta.last_page)}"
                v-if="meta.last_page > 1">
                <a class="page-link" href="javascript:void(0)" @click="paginate(meta.last_page)">
                    {{ meta.last_page }}
                </a>
            </li>
            <li class="page-item" v-if="meta.last_page !== meta.current_page">
                <a class="page-link" href="javascript:void(0)" aria-label="Next" @click="paginate(meta.current_page + 1)">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: "Pagination",
    props: ['meta'],
    methods: {
        paginate: function (page) {
            this.$emit('paginate', page);
        }
    },
    computed: {
        isCurrent: function () {
            return function (page) {
                return this.meta.current_page === page;
            }
        }
    }
}
</script>
